<template>
  <div class="login-wrapper container">
    <div class="row">
        <div class="col-12 col-md-3 d-flex align-items-center justify-content-center justify-content-md-end">
          <img class="logo" :src="logoUrl" alt="">
        </div>
        <div class="login-separator p-0 d-none d-md-block"></div>

        <div class="col-12 col-md-8 d-flex">
          <div class="login-content px-2 align-self-center">
            <slot></slot>
          </div>
      </div>
    </div>
    <notifications group="foo"/>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      logoUrl: process.env.VUE_APP_API_LINK + 'logo?tag=minerva&'+ Math.floor(Math.random() * 99999)
    };
  },
  mounted: function () {
     document.getElementById('app').classList.add('login'); // Add class to body
  },
  created: function () {
     document.getElementById('app').classList.add('login'); // Add class to body
  },
  destroyed: function () {
     document.getElementById('app').classList.remove('login');
  },
};
</script>
