import { render, staticRenderFns } from "./OtpEdit.vue?vue&type=template&id=c14f0c8c&"
import script from "./OtpEdit.vue?vue&type=script&lang=js&"
export * from "./OtpEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports