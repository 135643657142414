<template>
  <layout-login>
      <h1 class="" v-html="title"></h1>
      <p v-html="subtitle"></p>

      <!--begin::Form-->
      <form class="mt-5" action="" autocomplete="chrome-off" list="autocompleteOff" v-show="!isLoading">
        <div class="mb-3 form-group">
          <input
              class="form-control"
              type="text"
              placeholder="E-post"
              v-model="username"
              name="username"
              autocomplete="chrome-off"
              list="autocompleteOff"
          />
        </div>
        <div class="mb-3 form-group">
          <input
              class="form-control"
              type="password"
              placeholder="Lösenord"
              v-model="password"
              name="password"
              autocomplete="chrome-off"
              list="autocompleteOff"
              v-if="!forgotPassword"
          />
        </div>

        <div class="login-actions  mt-5 d-flex align-items-center justify-content-between">
          <a href="#" @click="forgotPassword = !forgotPassword" v-if="!forgotPassword">
            Glömt lösenord ?
          </a>
          <a href="#" @click="forgotPassword = !forgotPassword" v-if="forgotPassword">
            Tillbaka
          </a>
          <button class="btn btn-primary" v-on:click="login($event)" v-if="!forgotPassword">Fortsätt</button>
          <button class="btn btn-primary btn-small" v-on:click="newPassword($event)" v-if="forgotPassword">Begära nytt
            lösenord
          </button>
        </div>
      </form>
      <!--end::Form-->
  </layout-login>
</template>
<script>
import LayoutLogin from '@/views/Layouts/Login';
import BojAPI from '@/api/boj';
import store from '@/store';
import Cookies from '@/service/cookie';

export default {
  components: {
    LayoutLogin,
  },
  data() {
    return {
      forgotPassword: false,
      isLoading: false,
      username: '',
      password: '',
      title: 'Välkommen till <span>Minerva</span>',
      subtitle: 'Logga in för att fortsätta',
    };
  },
  props: {
    tokenExpired: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {

    if (Cookies.get('refreshTokenActive')) {
      this.$notify({
        group: 'foo',
        text: 'Du har loggats ut ur Minerva automatiskt. Det görs då och då av säkerhetsskäl. Du bara loggar in igen som vanligt.',
        type: 'warn',
        duration: 6000
      });

      Cookies.remove('refreshTokenActive');
    }

  },
  methods: {
    newPassword: function (event) {
      if (event) event.preventDefault();
      BojAPI.sendResetPasswordMail(this.username).then(() => {
        this.$notify({
          group: 'foo',
          text: 'Förfrågan om nytt lösenord har skickats. Kolla din e-post.',
          type: 'success',
          duration: 3000,
        });

        this.forgotPassword = false;
      });
    },

    login: function (event) {
      if (event) event.preventDefault();
      this.title = '<i class="fas fa-circle-notch fa-spin"></i> Vänligen vänta..';
      this.isLoading = true;
      this.subtitle = 'Testar åtkomstinformation..';
      let username = this.username;
      let password = this.password;
      this.$store
          .dispatch('login', {username, password})
          .then((response) => {
            this.subtitle = 'Loggar in...';

            if(response.otp_channel && !Cookies.get('otp')) {
              this.$router.push({ name: 'otp-verify' });
              return;
              
            }
                   
            this.$router.checkLoginData().then(() => {
              const urlParams = new URLSearchParams(window.location.search);

              if (store.getters.permissions.includes('section-access-calendar') && urlParams.get('redirect') == 'calendar') {
                this.$router.push('/calendar');
                return;
              }

              if (store.getters.permissions.includes('section-access-tickets')) {
                this.$router.push('/');
                return;
              }

              if (store.getters.permissions.includes('section-access-court')) {
                this.$router.push('/cases');
                return;
              }

              if (store.getters.permissions.includes('section-access-members')) {
                this.$router.push('/members');
                return;
              }

              if (store.getters.permissions.includes('section-access-offices')) {
                this.$router.push('/offices');
                return;
              }

              if (store.getters.permissions.includes('section-access-mail')) {
                this.$router.push('/email');
                return;
              }

              if (store.getters.permissions.includes('section-access-calendar')) {
                this.$router.push('/calendar');
                return;
              }

              this.$router.push('/no-access');

            });
              
          })
          .catch((error) => {
            if (error.response && error.response.status == 429) {
              this.title = 'Kontot spärrat';
              this.subtitle = 'Du har försökt logga in för många gånger med felaktiga uppgifter. Kom tillbaka om en stund och försök igen!';
              this.isLoading = false;

              return;
            }

            this.title = 'Pröva igen!';
            this.subtitle = 'Din e-post eller lösenord är fel!';
            this.isLoading = false;
          });
    },
  },
};
</script>
