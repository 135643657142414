<template>
  <layout-login>
    <h1 class="" v-html="title"></h1>
    <p v-html="subtitle"></p>
    <div class="sub-links">
      <p>
        <a class="url" href="#" v-if="state == 'overviewEmail'" @click="state = 'editEmail'">Sänd kod till annan
          e-postadress</a>
      </p>
      <p>
        <a class="" href="#" v-if="state == 'overviewSms'" @click="state = 'editSms'">Ändra mobilnummer</a>
      </p>
      <p>
        <a class="" href="#" v-if="state == 'overviewEmail'" @click="editOtpChannelSms($event)">Ändra veriferingsmetod
          till SMS</a>
      </p>
      <p>
        <a class="" href="#" v-if="state == 'overviewSms'" @click="editOtpChannelEmail($event)">Ändra verifieringsmetod
          till e-post</a>
      </p>
    </div>

    <form class="mt-5" action="" autocomplete="chrome-off" list="autocompleteOff" v-show="!isLoading">
      <div class="mb-3 form-group" v-if="state == 'editEmail'">
        <input class="form-control" type="text" placeholder="E-post" v-model="email" name="email"
          autocomplete="chrome-off" list="autocompleteOff" />
      </div>

      <div class="mb-3 form-group" v-if="state == 'editSms'">
        <input class="form-control" type="text" placeholder="SMS" v-model="sms" name="sms" autocomplete="chrome-off"
          list="autocompleteOff" />
      </div>

      <div class="login-actions  mt-5 d-flex align-items-center justify-content-between">
        <router-link :to="{ name: 'logout' }"
          v-if="['overviewEmail', 'overviewSms', 'unknown'].includes(state)">Tillbaka</router-link>

        <a href="#" @click="state = 'overviewEmail'" v-if="state == 'editEmail'">Tillbaka</a>
        <a href="#" @click="state = 'overviewSms'" v-if="state == 'editSms'">Tillbaka</a>
        <button class="btn btn-primary btn-small" v-if="state == 'overviewEmail'"
          v-on:click="verifyOtp($event)">Fortsätt</button>
        <button class="btn btn-primary btn-small" v-if="state == 'overviewSms'"
          v-on:click="verifyOtp($event)">Fortsätt</button>
        <button class="btn btn-primary btn-small" v-if="state == 'editEmail'"
          v-on:click="editEmail($event)">Ändra</button>

        <button class="btn btn-primary btn-small" v-if="state == 'editSms'" v-on:click="editSms($event)">Ändra</button>


      </div>
    </form>
  </layout-login>
</template>
<script>
import LayoutLogin from '@/views/Layouts/Login';
import BojAPI from '@/api/boj';
import store from '@/store';
import Cookies from '@/service/cookie';


export default {
  components: {
    LayoutLogin,
  },
  data() {
    return {
      state: '',
      otp_channel: 'email',
      isLoading: false,
      email: '',
      emailOld: '',
      sms: '',
      smsOld: '',
      title: 'Verifikation behövs',
    };
  },
  computed: {
    otpOptions() {
      return this.$store.getters.otpOptions;
    },
    subtitle() {
      if (this.state == 'overviewEmail') {
        return '<span class="text-light">Verifieringskoden går till ' + this.otpOptions.otp_email + '</span>';
      } else if (this.state == 'overviewSms') {
        return '<span class="text-light">Verifieringskoden går till ' + this.otpOptions.otp_sms + '</span>';
      } else if (this.state == 'editEmail') {
        return 'Ange en ny e-post';
      } else if (this.state == 'editSms') {
        return 'Ange nytt mobilnummer';
      } else {
        return '';
      }
    },
  },
  mounted() {
    this.email = this.otpOptions.otp_email;
    this.sms = this.otpOptions.otp_sms;
    this.smsOld = this.sms;
    this.emailOld = this.email;

    if (this.otpOptions.otp_channel == 'email') {
      this.state = 'overviewEmail';
    } else if (this.otpOptions.otp_channel == 'sms') {
      this.state = 'overviewSms';
    } else {
      this.state = 'unknown';
    }

  },
  methods: {
    editEmail(event) {
      if (event) event.preventDefault();

      if (this.email == this.emailOld) {
        this.state = 'overviewEmail';
        return;
      }

      BojAPI.updateOtpEmail(this.email)
        .then((response) => {
          this.$store.dispatch("setOtpOptions", response.data);
          this.$notify({
            group: 'foo',
            text: 'Verifieringsmmail har ändrats',
            type: 'success',
            duration: 3000,
          });
          this.state = 'overviewEmail';
          this.email = response.data.otp_email;
          this.emailOld = this.email;
        })
        .catch((error) => {
          this.$notify({
            group: 'foo',
            text: 'Felaktig email',
            type: 'error',
            duration: 3000,
          });
        });

    },
    editSms(event) {
      if (event) event.preventDefault();

      if (this.sms == this.smsOld) {
        this.state = 'overviewSms';
        return;
      }

      BojAPI.updateOtpSms(this.sms)
        .then((response) => {
          this.$store.dispatch("setOtpOptions", response.data);
          this.$notify({
            group: 'foo',
            text: 'Mobilnumret har ändrats',
            type: 'success',
            duration: 3000,
          });
          this.state = 'overviewSms';
          this.sms = response.data.otp_sms;
          this.smsOld = this.sms;
        })
        .catch((error) => {
          this.$notify({
            group: 'foo',
            text: 'Felaktig mobilnummer',
            type: 'error',
            duration: 3000,
          });

        });


    },
    editOtpChannelSms(event) {
      if (event) event.preventDefault();
      BojAPI.updateOtpChannel('sms')
        .then((response) => {
          this.$store.dispatch("setOtpOptions", response.data);

        })
        .catch((error) => { });
      this.state = 'editSms';
    },
    editOtpChannelEmail(event) {
      if (event) event.preventDefault();
      BojAPI.updateOtpChannel('email')
        .then((response) => {
          this.$store.dispatch("setOtpOptions", response.data);


        })
        .catch((error) => { });

      this.state = 'editEmail';
    },

    verifyOtp(event) {
      if (event) event.preventDefault();

      this.$router.push({ name: 'otp-verify' });



    },
  },
};
</script>

